import { useEffect, useState } from 'react';

export default function Global(props) {
  const IMAGE_SET_AMOUNT = 5;
  const LOADING_RESET_TIME = 100;
  const LOAD_BOTTOM_OFFSET = 200;
  const { data } = props;
  const gallery = (data && data.galleries[0]) || [];
  const title = data && data.content.content[0].content[0].value;

  const [imageSet, setImageSet] = useState(1);
  const [loading, setLoading] = useState(false);

  const getCurrentImages = () => {
    const currentImages = [];

    if (!gallery || !gallery.fields.mediaItems.length) return [];

    const imageAmount = gallery.fields.mediaItems.length > (IMAGE_SET_AMOUNT - 1)
      ? (IMAGE_SET_AMOUNT * imageSet)
      : gallery.fields.mediaItems.length;

    for (let i = 0; i < imageAmount; i++) {
      if (i < imageAmount && i < gallery.fields.mediaItems.length) {
        currentImages.push(gallery.fields.mediaItems[i]);
      }
    }

    return currentImages;
  };

  const currentImages = getCurrentImages();

  const scrollHandler = () => {
    if ((window.innerHeight + window.pageYOffset)
      >= (document.body.offsetHeight - LOAD_BOTTOM_OFFSET)
      && currentImages.length <= gallery.fields.mediaItems.length
      && !loading) {
      setLoading(true);
      setImageSet((curSet) => curSet + 1);

      setTimeout(() => {
        setLoading(false);
      }, LOADING_RESET_TIME);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', () => {
      window.requestAnimationFrame(scrollHandler);
    });
  }, []);

  return (
    <div className="component-global">
      <div>
        <h1 className="component-global__description">{title}</h1>
        <div className="component-global__images grid">
          {currentImages.map((item, index) => {
            const imageUrl = item.fields.file.fields.file.url;

            return (
              <img
                alt={item.fields.title}
                className="component-global__image"
                key={item.fields.file.fields.file.fileName}
                src={`https:${imageUrl}?w=400&fl=progressive&fm=jpg&q=50`}
                srcSet={`https:${imageUrl}?w=700&fl=progressive&fm=jpg&q=65 1000w, https:${imageUrl}?w=1000&fl=progressive&fm=jpg&q=75 2000w`}
                style={{
                  gridColumn: `${item.fields.gridXPosition}/${item.fields.gridXPosition + item.fields.gridWidth}`,
                  gridRowStart: item.fields.gridYPosition,
                  zIndex: (880 - index),
                }}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
