import {
  Link,
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  useCatch,
  useLoaderData,
  redirect,
} from 'remix';
import { useLocation } from 'react-router-dom';
import Global from './components/global';
import contentfulClient from './services/contentful';

import globalStylesUrl from './styles/global.css';
import mainStylesUrl from './styles/root.css';

const CONTENT_INDEX_PAGE_ID = '2sj9ecp24elpPjl91RvbJj';

export const links = () => [
  { rel: 'stylesheet', href: mainStylesUrl },
  { rel: 'stylesheet', href: globalStylesUrl },
];

export const loader = async ({ request }) => {
  // upgrade people to https automatically
  const url = new URL(request.url);
  const { hostname } = url;
  const proto = request.headers.get('X-Forwarded-Proto') || url.protocol;

  url.host = request.headers.get('X-Forwarded-Host')
    || request.headers.get('host')
    || url.host;
  url.protocol = 'https:';

  if (proto === 'http' && hostname !== 'localhost') {
    return redirect(url.toString(), {
      headers: {
        'X-Forwarded-Proto': 'https',
      },
    });
  }

  const data = await contentfulClient.getEntry(CONTENT_INDEX_PAGE_ID, { include: 2 });
  return data;
};

export default function App() {
  return (
    <Document>
      <Layout>
        <Outlet />
      </Layout>
    </Document>
  );
}

export function ErrorBoundary({ error }) {
  return (
    <Document title="Error!">
      <Layout>
        <div>
          <h1>There was an error</h1>
          <p>{error.message}</p>
          <hr />
          <p>
            Hey, developer, you should replace this with what you want your
            users to see.
          </p>
        </div>
      </Layout>
    </Document>
  );
}

export function CatchBoundary() {
  const caught = useCatch();

  let message;
  switch (caught.status) {
    case 401:
      message = (
        <p>
          Oops! Looks like you tried to visit a page that you do not have access
          to.
        </p>
      );
      break;
    case 404:
      message = (
        <p>Oops! Looks like you tried to visit a page that does not exist.</p>
      );
      break;

    default:
      throw new Error(caught.data || caught.statusText);
  }

  return (
    <Document title={`${caught.status} ${caught.statusText}`}>
      <Layout>
        <h1>
          {caught.status}
          :
          {' '}
          {caught.statusText}
        </h1>
        {message}
      </Layout>
    </Document>
  );
}

function Document({ children, title }) {
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <link rel="apple-touch-icon" sizes="180x180" href="favicon/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="favicon/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="favicon/favicon-16x16.png" />
        <link rel="manifest" href="favicon/site.webmanifest" />
        {title ? <title>{title}</title> : null}
        <Meta />
        <Links />
      </head>
      <body>
        {children}
        <Scripts />
        {process.env.NODE_ENV === 'development' && <LiveReload />}
      </body>
    </html>
  );
}

function Layout({ children }) {
  const loaderData = useLoaderData();
  const globalData = loaderData && loaderData.fields;
  const location = useLocation();

  return (
    <div className="app">
      <header className="app__header">
        <div className="container app__header-content">
          <nav aria-label="Main navigation" className="app__header-nav">
            <ul className="grid">
              <li className="app__header-nav__item ahni__one">
                {location && location.pathname === '/contact' && <Link to="/">Contact</Link>}
                {(!location || location.pathname !== '/contact') && <Link to="/contact">Contact</Link>}
              </li>
              <li className="app__header-nav__item ahni__two">
                {location && location.pathname === '/fine-art' && <Link to="/">Fine Art</Link>}
                {(!location || location.pathname !== '/fine-art') && <Link to="/fine-art">Fine Art</Link>}
              </li>
              <li className="app__header-nav__item ahni__three">
                {location && location.pathname === '/creative' && <Link to="/">Creative</Link>}
                {(!location || location.pathname !== '/creative') && <Link to="/creative">Creative</Link>}
              </li>
            </ul>
          </nav>
        </div>
      </header>
      <div className="app__main">
        <Global data={globalData} />
        <div className="container app__main-content">{children}</div>
      </div>
      <footer className="app__footer" />
    </div>
  );
}
